<template>
  <!-- container -->
  <div id="container">
    <!-- 본문 -->
    <div id="contents">
      <div class="partners">
        <!-- 서브 배너 -->
        <section class="subTopArea inner-fixed">
          <h2 v-html="$t('views.partners.banner')"></h2>
          <div class="btn-cen">
            <a @click="handleLink('business')" class="button-go-point">{{ $t('views.partners.business') }}</a>
          </div>
        </section>
        <!-- //서브 배너 -->
        <section class="partnersWrap inner-fixed">
          <div class="areaBox">
            <h3>{{ $t('views.partners.corp') }}</h3>
            <ul class="partnersBox">
              <li><img src="@/assets/images/image-enterprise-1.png" srcset="@/assets/images/image-enterprise-1@2x.png 2x,@/assets/images/image-enterprise-1@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-2.png" srcset="@/assets/images/image-enterprise-2@2x.png 2x,@/assets/images/image-enterprise-2@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-3.png" srcset="@/assets/images/image-enterprise-3@2x.png 2x,@/assets/images/image-enterprise-3@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-4.png" srcset="@/assets/images/image-enterprise-4@2x.png 2x,@/assets/images/image-enterprise-4@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-5.png" srcset="@/assets/images/image-enterprise-5@2x.png 2x,@/assets/images/image-enterprise-5@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-6.png" srcset="@/assets/images/image-enterprise-6@2x.png 2x,@/assets/images/image-enterprise-6@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-7.png" srcset="@/assets/images/image-enterprise-7@2x.png 2x,@/assets/images/image-enterprise-7@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-8.png" srcset="@/assets/images/image-enterprise-8@2x.png 2x,@/assets/images/image-enterprise-8@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-9.png" srcset="@/assets/images/image-enterprise-9@2x.png 2x,@/assets/images/image-enterprise-9@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-11.png" srcset="@/assets/images/image-enterprise-11@2x_.png" alt=""></li>
              <!-- <li><img src="@/assets/images/image-enterprise-11.png" srcset="@/assets/images/image-enterprise-11@2x_2.png 2x,@/assets/images/image-enterprise-11@3x.png 3x" alt=""></li> -->
              <li><img src="@/assets/images/image-enterprise-12.png" srcset="@/assets/images/image-enterprise-12@2x.png 2x,@/assets/images/image-enterprise-12@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-13.png" srcset="@/assets/images/image-enterprise-13@2x.png 2x,@/assets/images/image-enterprise-13@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-14.png" srcset="@/assets/images/image-enterprise-14@2x.png 2x,@/assets/images/image-enterprise-14@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-15.png" srcset="@/assets/images/image-enterprise-15@2x.png 2x,@/assets/images/image-enterprise-15@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-16.png" srcset="@/assets/images/image-enterprise-16@2x.png 2x,@/assets/images/image-enterprise-16@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-17.png" srcset="@/assets/images/image-enterprise-17@2x.png 2x,@/assets/images/image-enterprise-17@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-18.png" srcset="@/assets/images/image-enterprise-18@2x.png 2x,@/assets/images/image-enterprise-18@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-19.png" srcset="@/assets/images/image-enterprise-19@2x.png 2x,@/assets/images/image-enterprise-19@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-20.png" srcset="@/assets/images/image-enterprise-20@2x.png 2x,@/assets/images/image-enterprise-20@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-21.png" srcset="@/assets/images/image-enterprise-21@2x.png 2x,@/assets/images/image-enterprise-21@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-22.png" srcset="@/assets/images/image-enterprise-22@2x.png 2x,@/assets/images/image-enterprise-22@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-23.png" srcset="@/assets/images/image-enterprise-23@2x.png 2x,@/assets/images/image-enterprise-23@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-enterprise-24.png" srcset="@/assets/images/image-enterprise-24@2x.png 2x,@/assets/images/image-enterprise-24@3x.png 3x" alt=""></li>
            </ul>
          </div>
          <div class="areaBox">
            <h3>{{ $t('views.partners.partner') }}</h3>
            <ul class="partnersBox">
              <li><img src="@/assets/images/image-partner-1.png" srcset="@/assets/images/image-partner-1@2x.png 2x,@/assets/images/image-partner-1@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-2.png" srcset="@/assets/images/image-partner-2@2x.png 2x,@/assets/images/image-partner-2@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-3.png" srcset="@/assets/images/image-partner-3@2x.png 2x,@/assets/images/image-partner-3@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-4.png" srcset="@/assets/images/image-partner-4@2x.png 2x,@/assets/images/image-partner-4@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-5.png" srcset="@/assets/images/image-partner-5@2x.png 2x,@/assets/images/image-partner-5@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-6.png" srcset="@/assets/images/image-partner-6@2x.png 2x,@/assets/images/image-partner-6@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-7.png" srcset="@/assets/images/image-partner-7@2x.png 2x,@/assets/images/image-partner-7@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-8.png" srcset="@/assets/images/image-partner-8@2x.png 2x,@/assets/images/image-partner-8@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-9.png" srcset="@/assets/images/image-partner-9@2x.png 2x,@/assets/images/image-partner-9@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-10.png" srcset="@/assets/images/image-partner-10@2x.png 2x,@/assets/images/image-partner-10@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-11.png" srcset="@/assets/images/image-partner-11@2x.png 2x,@/assets/images/image-partner-11@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-12.png" srcset="@/assets/images/image-partner-12@2x.png 2x,@/assets/images/image-partner-12@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-13.png" srcset="@/assets/images/image-partner-13@2x.png 2x,@/assets/images/image-partner-13@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-14.png" srcset="@/assets/images/image-partner-14@2x.png 2x,@/assets/images/image-partner-14@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-partner-15.png" srcset="@/assets/images/image-partner-15@2x.png 2x,@/assets/images/image-partner-15@3x.png 3x" alt=""></li>
            </ul>
          </div>
          <div class="areaBox">
            <h3>{{ $t('views.partners.public') }}</h3>
            <ul class="partnersBox">
              <li><img src="@/assets/images/image-public-institution-1.png" srcset="@/assets/images/image-public-institution-1@2x.png 2x,@/assets/images/image-public-institution-1@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-2.png" srcset="@/assets/images/image-public-institution-2@2x.png 2x,@/assets/images/image-public-institution-2@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-3.png" srcset="@/assets/images/image-public-institution-3@2x.png 2x,@/assets/images/image-public-institution-3@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-4.png" srcset="@/assets/images/image-public-institution-4@2x.png 2x,@/assets/images/image-public-institution-4@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-5.png" srcset="@/assets/images/image-public-institution-5@2x.png 2x,@/assets/images/image-public-institution-5@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-6.png" srcset="@/assets/images/image-public-institution-6@2x.png 2x,@/assets/images/image-public-institution-6@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-7.png" srcset="@/assets/images/image-public-institution-7@2x.png 2x,@/assets/images/image-public-institution-7@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-8.png" srcset="@/assets/images/image-public-institution-8@2x.png 2x,@/assets/images/image-public-institution-8@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-9.png" srcset="@/assets/images/image-public-institution-9@2x.png 2x,@/assets/images/image-public-institution-9@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-10.png" srcset="@/assets/images/image-public-institution-10@2x.png 2x,@/assets/images/image-public-institution-10@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-11.png" srcset="@/assets/images/image-public-institution-11@2x.png 2x,@/assets/images/image-public-institution-11@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-12.png" srcset="@/assets/images/image-public-institution-12@2x.png 2x,@/assets/images/image-public-institution-12@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-13.png" srcset="@/assets/images/image-public-institution-13@2x.png 2x,@/assets/images/image-public-institution-13@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-14.png" srcset="@/assets/images/image-public-institution-14@2x.png 2x,@/assets/images/image-public-institution-14@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-15.png" srcset="@/assets/images/image-public-institution-15@2x.png 2x,@/assets/images/image-public-institution-15@3x.png 3x" alt=""></li>
              <li><img src="@/assets/images/image-public-institution-16.png" srcset="@/assets/images/image-public-institution-16@2x.png 2x,@/assets/images/image-public-institution-16@3x.png 3x" alt=""></li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import { handleLink } from '@/assets/js/util.js'

export default {
  methods: {
    handleLink (path, query) { handleLink(this.$router, path, query) }
  },
}
</script>

<style>

</style>
